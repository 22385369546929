<template>
  <div
    class="min-vh-100 d-flex flex-column align-items-center justify-content-center bg-light"
  >
    <div
      class="d-flex flex-column align-items-center justify-content-center flex-grow-1 max-w-540 text-center"
    >
      <img :src="require(`@/assets/images/lost.svg`)" alt="404" />
      <h4 class="text-primary th-mt-32">
        {{ $translation.t("Oops! You are lost in page") }}
      </h4>
      <p class="body-2 th-mt-16">
        {{
          $translation.t(
            "It looks like the page you’re trying to visit has been moved or doesn’t exist anymore. Please check the address to make sure you’ve spelled things right or go to home."
          )
        }}
      </p>
      <v-btn color="primary" class="th-mt-40" large :to="home">
        {{ $translation.t("Back to home") }}
      </v-btn>
    </div>
    <small class="annotation th-my-24">
      <span class="body2">{{ $translation.t("Powered by") }}</span>
      <header-logo height="24" :light="false" class="th-ml-8-lg-minus" />
    </small>
  </div>
</template>

<script>
import HeaderLogo from "@/components/Layout/Header/HeaderLogo";
export default {
  name: "Lost",
  components: { HeaderLogo },
  computed: {
    home() {
      return { name: "home", params: this.$route.params };
    },
  },
};
</script>
